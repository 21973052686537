import React,{useRef, useState} from 'react'
import Map from '../map'
import OwlCarousel from 'react-owl-carousel3';
import { connect } from 'react-redux'
import Breadcrumbs from "../breadcrumbs";
import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox';
import { graphql, useStaticQuery } from 'gatsby';
import SliderComponent from "../slider.jsx"

//Helpers
import {getCountUnities,getMinAndMaxPrice} from '../../helpers/helper.unities';
import { getLocation,getStatus, getContructionDate, getName, getTitle, getFakeAddress, getType } from '../../helpers/helper.developments';
import {getPhotos,getDescription,getQuality, getVideos } from '../../helpers/helper.rendering';
import {getTour,getLastVideo, getTours, getBluePrints} from '../../helpers/helper.properties';
// import ButtonBox from './openLightBox';

import Loading from '../../components/Loading'
// import Slider from 'react-slick';
import Slider from "react-slick";
import FilesPro from './files-pro';

const About = ({development,unities,loading,dispatch}) => {
    const [positionSlider,setPositionSlider] = useState(1)
    const sliderRef = useRef()
    const slider = useRef()
    const { openLightbox, closeLightbox } = useLightbox()
    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            template_version
            design {
                icons
            }
        }
    }`)

    const { design: { icons } } = realEstate
    const MediaPro = realEstate.template_version === 'PRO' ? true : false;
    const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

    const options = {
        settings: {
            overlayColor: "rgba(0, 0, 0, 0.9)",
            autoplaySpeed: 1500,
            transitionSpeed: 900,
            disableWheelControls:true
        },
        buttons: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            iconColor: "rgba(255,255,255, 1)",
        },
        caption: {
            captionColor: "#000",
            captionFontFamily: "Raleway, sans-serif",
            captionFontWeight: "300",
            captionTextTransform: "uppercase",
        }
    };

    const settingsSlick = {
        dots: false,
        infinite: true,
        speed: 500,
        fade:false,
        slidesToShow: 1,
        slidesToScroll: 1
      };
                                    

    return !loading ? (
        <section class="ficha-about dev">
            <div class="about-top">
                <div class="container-fluid">
                    <div class="d-flex flex-wrap align-items-lg-start justify-content-lg-between justify-content-center text-venter">
                        <div class="column 6 col-12 d-lg-none d-block order-1 order-lg-6">
                            <div class="row with-border py-2">
                                <div class="order-lg-1 col-12 pe-lg-5 py-4 py-lg-0 d-flex align-items-start justify-content-evenly flex-lg-column">
                                    {unities.length === undefined ? (
                                        ''
                                    ):
                                        <> 
                                            <div class="item mb-lg-4 d-lg-block">
                                                <p class="mb-lg-0 me-lg-4 text-center">Desde</p>
                                                <h4>{"USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).min)}</h4>
                                            </div>
                                            <div class="item d-lg-block">
                                                <p class="me-lg-4 mb-lg-0 text-center">Hasta</p>
                                                <h4>{"USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).max)}</h4>
                                            </div>                                    
                                        </>
                                     }
                                </div>
                            </div>
                        </div>
                        <div class="column pb-4 py-4 py-lg-4 col-6 col-lg-auto d-none d-lg-block 2 order-lg-2 order-2">
                            <i className={`icon-building${iconsStyle} me-lg-0 text-center text-lg-start`}></i>
                            <div class="info text-center text-lg-start">
                                <h3 class="mb-2 mb-lg-1">TIPO</h3>
                                <p>{getType(development)}</p>
                            </div>                    
                        </div>
                        <div class="column pb-4 py-4 py-lg-4 col-6 col-lg-auto d-block 2 order-lg-2 order-2">
                            <i className={`icon-construction_state${iconsStyle} me-lg-0 text-center text-lg-start`}></i>
                            <div class="info text-center text-lg-start">
                                <h3 class="mb-2 mb-lg-1">ESTADO</h3>
                                <p>{getStatus(development)}</p>
                            </div>                    
                        </div>
                        <div class="column py-4 py-lg-4 col-6 col-lg-auto d-block 1 order-lg-3 order-3">
                            <i className={`icon-unit${iconsStyle} me-lg-0 text-center text-lg-start`}></i>
                            <div class="info text-center text-lg-start">
                                <h3 class="mb-2 mb-lg-1">UNIDADES DISPONIBLES</h3>
                                <p>{getCountUnities(unities) || 'Sin especificar'}</p>
                            </div>                    
                        </div>
                        <div class="column py-4 py-lg-4 col-6 col-lg-auto d-block 3 order-lg-4 order-4">
                            <i className={`icon-location${iconsStyle} me-lg-0 text-center text-lg-start`}></i>
                            <div class="info text-center text-lg-start">
                                <h3 class="mb-2 mb-lg-1">UBICACIÓN</h3>
                                <p>{getLocation(development,true)[0]} <br /> {getLocation(development,true)[1]}</p>
                            </div>                    
                        </div>
                        <div class="column py-4 py-lg-4 col-6 col-lg-auto d-block 4 order-lg-5 order-5">
                            <i className={`icon-delivery-date${iconsStyle} me-lg-0 text-center text-lg-start`}></i>
                            <div class="info text-center text-lg-start">
                                <h3 class="mb-2 mb-lg-1">FECHA DE ENTREGA</h3>
                                <p>{getContructionDate(development) || 'Sin especificar'}</p>
                            </div>                    
                        </div>
                        <div class="column col-lg-3 col-12 6 d-lg-block d-none order-1 order-lg-6">
                            <div class="row with-border py-2">
                                <div class="col-12 pe-lg-5 py-4 py-lg-0 d-flex align-items-start justify-content-end flex-column">
                                    {unities.length === undefined ? (
                                        ''
                                    ):
                                        <> 
                                            <div class="item mb-lg-2 d-flex d-lg-block">
                                                <p class="mb-4 mb-lg-0 mr-4">Desde</p>
                                                <h4>{"USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).min)}</h4>
                                            </div>
                                            <div class="item d-flex d-lg-block">
                                                <p class="mr-4 mb-lg-0">Hasta</p>
                                                <h4>{"USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).max)}</h4>
                                            </div>                                    
                                        </>
                                     }
                                </div>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>
            
            <div class="concepto">
                <div class="container-fluid">
                    <div class="row intro">
                        <div className="col-lg-12">
                            <Breadcrumbs
                                props={[
                                { name: realEstate.name, route: "/", location: "" },
                                { name: "Emprendimientos", route:   "/emprendimientos", location: ""},
                                { name: getName(development), route: "", location: ""},
                                ]}
                            />
                        </div>
                        <div class="col-lg-2">
                            <h2 class="section-name">Concepto</h2>
                        </div>
                        <div class="col-lg-10" >
                            <p>{getTitle(development)}</p>
                        </div>
                    </div>     
                </div>
              
            </div>
            <div class="ubicacion-main">
                <div class="container-fluid">
                    <div class={"row intro" + (!MediaPro ? "py-5 mt-lg-0 mb-lg-5 pt-lg-0" : ' py-lg-0 pt-5 pb-0')}>
                        <div class="col-lg-2">
                            <h2 class="section-name">Descripción</h2>
                        </div>
                        <div class="col-lg-10">
                            <p class="description-ficha">
                                {getDescription(development,99999).map((description,index) => (
                                    <>
                                        {description}
                                        <br />
                                    </>
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {!MediaPro && 
                <>
                    <div id="section-galery">
                        <div class="" id="dev-secuencial">
                            <div class="slider" id="dev-gallery">
                                <span className="conPag onTop">{positionSlider} / {getPhotos(development).length}</span>
                                <SimpleReactLightbox>
                                    <SRLWrapper options={options}>
                                        <Slider afterChange={(e) => setPositionSlider(e+1)} ref={sliderRef} {...settingsSlick}>
                                            {getPhotos(development).map((photo,index) => (
                                                <div class="item banner-main banner-ficha emprendimiento">
                                                    <img src={getQuality(photo)} className='object-cover object-center cursor-pointer' alt={realEstate?.name} />
                                                </div>
                                            ))}
                                        </Slider>
                                        <span className="legend-fullscreen">Abrir galería de imágenes</span>
                                    </SRLWrapper>
                                </SimpleReactLightbox>
                                <div className={"arrows-left onTop zindex-1"  + (getPhotos(development)?.length === 1 || positionSlider === 1 ? ' d-none' : '')}>
                                    <div className="arrow" onClick={() => sliderRef.current.slickPrev()}> <i></i> <i></i></div>
                                </div>
                                <div className={"arrows-right onTop zindex-1"  + (getPhotos(development)?.length === 1 || positionSlider === getPhotos(development)?.length ? ' d-none' : '')}>
                                    <div className="arrow" onClick={() => sliderRef.current.slickNext()}> <i></i> <i></i> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div id="video" class={"row global-view " + (getVideos(development).length === 0 ? 'd-none' : '')}>
                            <SliderComponent files={getVideos(development)} type={"videos"} />
                        </div>
                        <div id="tour360" class={"row global-view " + (getTours(development).length === 0 ? 'd-none' : '')}>
                            <SliderComponent files={getTours(development)} type={"tours"} />
                        </div>
                        <div id="blueprints" class={"row global-view " + (getBluePrints(development).length === 0 ? 'd-none' : '')}>
                            <SliderComponent files={getBluePrints(development)} type={"images"} />
                        </div>
                    </div>
                </>
            }
            <FilesPro files={development.files} />
            <div class="ubicacion-main mt-lg-5 mt-4">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="section-name mb-lg-2">Ubicación</h4>
                            <p class="mb-4 pb-lg-0 pb-1">
                            { getFakeAddress(development) + ", " + getLocation(development,true)[0]}<br />
                            {getLocation(development,true)[1]}
                            </p>
                        </div>
                        <div class="col-lg-12">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <Map coordenadas={{ geo_lat: development.geo_lat, geo_long: development.geo_long }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):
    <>
      <Loading absolute={true}/>
    </>
}

export default connect(state => ({
    development: state.developments.development,
    loading: state.developments.loading,
    unities: state.developments.unities,
}),null)(About);